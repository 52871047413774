import React from 'react';
import PrintLine from '../../svg/print-line.svg?react';
import style from '../../styles/components/printBtn.module.scss';
import { useTranslation } from 'react-i18next';
import jsPDF from 'jspdf';
import { toJpeg } from 'html-to-image';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { shareFile } from '../../../../Utils/share-file';
import write_blob from 'capacitor-blob-writer';

const NewPrintButton = ({ absolutePosition }) => {
  const { t } = useTranslation();

  const printContent = () => {
    const reportElement = document.getElementById('report-container');
    if (!reportElement) {
      console.error('Элемент report-container не найден');
      return;
    }

    const printWindow = window.open('', '_blank');

    const styles = Array.from(document.querySelectorAll('link[rel="stylesheet"], style'))
      .map((node) => node.outerHTML)
      .join('\n');

    const headNodes = document.querySelectorAll('head link[rel="stylesheet"], head style');
    headNodes.forEach((node) => {
      printWindow.document.head.appendChild(node.cloneNode(true));
    });

    printWindow.document.write(`
      <html>
        <head>
          <title>Отчет</title>
          ${styles}
          <style>
              body {
                margin: 0;
                padding: 0;
                font-family: sans-serif;
              }
          </style>
        </head>
        <body>
          ${reportElement.innerHTML}
        </body>
      </html>
    `);

    printWindow.document.close();
    
    printWindow.onload = () => {
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    };
  };

  const print = async () => {
    if (Capacitor.isNativePlatform()) {
      const element = document.getElementById('report-container');

      if (!element) {
        console.error('Элемент не найден');
        return;
      }

      const img = await toJpeg(element, {
        backgroundColor: '#ffffff',
      });

      const pdf = new jsPDF('p', 'pt', 'a4');
      const imgProperties = pdf.getImageProperties(img);
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      const scale = Math.min(pageWidth / imgProperties.width, pageHeight / imgProperties.height);
      const imgWidth = imgProperties.width * scale;
      const imgHeight = imgProperties.height * scale;

      const xOffset = (pdf.internal.pageSize.getWidth() - imgWidth) / 2;
      const yOffset = (pdf.internal.pageSize.getHeight() - imgHeight) / 2;

      pdf.addImage(img, 'JPEG', xOffset, yOffset, imgWidth, imgHeight);

      const pdfBytes = pdf.output('arraybuffer');

      const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

      const fileName = `report.pdf`;

      await write_blob({
        path: fileName,
        directory: Directory.Documents,
        blob: pdfBlob,
      });

      const fileUri = await Filesystem.getUri({
        directory: Directory.Documents,
        path: fileName,
      });

      await shareFile(fileName, fileUri.uri);
    } else {
      printContent();
    }
  };

  return (
    <button
      className={`${style.btn} ${style['btn--primary']} ${
        absolutePosition ? style['btn--absolute'] : ''
      }`}
      onClick={print}
    >
      <PrintLine />
      <span className={style['ml-10']}>{t('close_shift.print')}</span>
    </button>
  );
};

export default NewPrintButton;
