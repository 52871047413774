import React from 'react';

export default function SpinnerWhite({ className = '' }) {
    return (
        <span className={`ldSpinner ${className}`}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </span>
    )
}

export function SpinnerPrimary({ className = '' }) {
    return (
        <span className={`ldSpinner LdSpinner--primary ${className}`}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </span>
    )
}