import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../Store/snippets';
import { SpinnerPrimary } from '../Common/Spinner';
import style from './checkpage.module.scss';

const CheckPage = () => {
    const [img, setImg] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        const Token = localStorage.getItem("tokken");

        if (params.datetime && params.znm && params.fn && params.total) {
            const receiptUrl = `${BASE_URL}/receipt?datetime=${params.datetime}&znm=${params.znm}&fn=${params.fn}&total=${params.total}`;
            fetch(receiptUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Token}`,
                },
            })
            .then((response) => response.json())
            .then((data) => {
                setImg(data.Data);
            })
            .finally(() => {
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, []);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());

    return (
        <div className={`${style.checkPage} ${loading ? style.loading : ''}`}>
            {loading ? (
                <div className={style.spinner}>
                    <SpinnerPrimary />
                </div>
            ) : params.datetime && params.znm && params.fn && params.total ? (
                img ? (
                    <div className={style.wrapper}>
                        <div className={style.title}>
                            Дубликат чека:
                        </div>
                        <img
                            className={style.image}
                            id="receipt"
                            src={`data:image/png;base64,${img}`}
                            alt="Check"
                        />
                    </div>
                ) : (
                    <div className={`${style.message} ${style.messageExpired}`}>
                        Срок годности чека истек
                    </div>
                )
            ) : (
                <div className={`${style.message} ${style.messageInvalid}`}>
                    Неверная ссылка
                </div>
            )}
        </div>
    );
};

export default CheckPage;
