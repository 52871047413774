import React from 'react';
import axios from 'axios';
import { Capacitor } from '@capacitor/core';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import * as SentryReact from "@sentry/react";
import * as SentryCapacitor from "@sentry/capacitor";

import App from './App';
import * as serviceWorker from './serviceWorker';
import { SENTRY_DSN } from './constants/app';

serviceWorker.unregister();

if (Capacitor.getPlatform() === 'ios') {
	Capacitor.Plugins.CapacitorSwipeBackPlugin.enable().then(response => {
		console.log('Swipe back enabled:', response);
	}).catch(error => {
		console.error('Error enabling swipe back:', error);
	});
}

console.log('================= import.meta.env.MODE', import.meta.env.MODE)

if (import.meta.env.MODE === 'production') {
	if (Capacitor.isNativePlatform()) {
		SentryCapacitor.init({
			dsn: SENTRY_DSN,
			release: "fiscal-front@1.0.0",
			dist: "1",
			integrations: [
				new Sentry.Integrations.CaptureConsole(),
			],
			tracesSampleRate: 1.0,
			tracePropagationTargets: [
				"https://api.fiscalv2.tech24.kz", 
				"https://fiscal2.kassa24.kz"
			],
			replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: 1.0,
		});
	} else {
		SentryReact.init({
			dsn: SENTRY_DSN,
			release: "fiscal-front@1.0.0",
			dist: "1",
			integrations: [
				SentryReact.browserTracingIntegration(),
				SentryReact.replayIntegration(),
			],
			tracesSampleRate: 1.0,
			tracePropagationTargets: [
				"localhost", 
				"https://api.fiscalv2.tech24.kz", 
				"https://fiscal2.kassa24.kz"
			],
			replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: 1.0,
		});
	}

	axios.interceptors.response.use((response) => response, (error) => {
		Sentry.captureException(error);
		return Promise.reject(error);
	});
}

const startApp = () => {
	if (
		window.matchMedia("(min-width: 740px)").matches
		&& window.matchMedia("(min-height: 500px)").matches
		&& window.matchMedia("(max-height: 800px)").matches
	) {
		document.documentElement.style = "font-size: 11px";
	}

	createRoot(document.getElementById("root")).render(<Router><App /></Router>);
}

startApp();
