import React, { useRef } from 'react'
import { Search } from '../../../Icons'
import s from './searchfield.module.scss'

const SearchField = ({ searchValue, setSearchValue, searchOpen, setSearchOpen, placeholder, onSearchChange, onSearchSubmit }) => {
	const ref = useRef()

	const openSearchInput = () => {
		setSearchOpen(true);

		if (ref.current) {
			ref.current.focus();
		}

		searchOpen && onSearchSubmit();
	}

	const closeSearchInput = () => {
		setTimeout(() => {
			if (ref.current && !ref.current.contains(document.activeElement)) {
				setSearchOpen(false);
			}
		}, 100)
	}

	const handleChange = (e) => {
		const newValue = e.target.value.trim();
		setSearchValue(newValue);

		if (onSearchChange) {
			onSearchChange(newValue);
		}
	}

	const handleKeyDown = (e) => {
		if (e.key === 'Enter' && onSearchSubmit) {
			onSearchSubmit();
		}
	}

	return (
		<div className={!searchOpen ? s.wrapper : `${s.wrapper} ${s.active}`}>
			<input
				ref={ref}
				value={searchValue}
				onChange={handleChange}
				onBlur={closeSearchInput}
				onKeyDown={handleKeyDown}
				placeholder={placeholder}
			/>
			<i
				onClick={openSearchInput}
				className={s.search_button}
			>
				<Search />
			</i>
		</div>
	)
}

export default SearchField
