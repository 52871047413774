import React, {useEffect, useState} from "react";
import NewPrintButton from "../modules/NewPrintButton";
import style from '../../styles/pages/report/tabs/NewReportX.module.scss'
import ArrowDownSLine from '../../svg/arrow-down-s-line.svg?react'
import ArrowUpSLine from '../../svg/arrow-up-s-line.svg?react'
import Loader from "../loader/Loader";
import { useTranslation } from "react-i18next";
import NewDownloadPDFButton from "../modules/NewDownloadPDFButton";
import {formatAmount} from "../../../../Store/snippets";

const NewReportX = ({ reportX, user, getReportX, isLoading }) => {
	const { t } = useTranslation()

	const [infoMobileIsCollapsed, setInfoMobileIsCollapsed] = useState(false)

	useEffect(() => {
		getReportX()
	}, [])

	return (
		<React.Fragment>
			{
				!isLoading ?
					<React.Fragment>
						{ reportX && reportX.Kkm
							?
							<div id="report-container" className={`${style['flex-4']} ${style['mt-20']} pdf-styles`}>
								<div className={`${style['print-title']}`} >{ t('report.x_report') }</div>
								<div className={`${style['top-table']}`} >
									<div className={`${style.card} ${style['card--white-nopadding']}`}>
										<div className={style.table}>
											<div className={`${style['table__content']} ${infoMobileIsCollapsed && style['collapsed']}`}>
												<div
													className={style['table__header']}
													onClick={() => {
														setInfoMobileIsCollapsed(!infoMobileIsCollapsed)
													}}
												>
													<div>{ t('report.main_information') }</div>
													<button className={`${style.btn} ${style['btn--icon']}`}>
														<ArrowDownSLine/>
													</button>
													<button className={`${style.btn} ${style['btn--icon']}`}>
														<ArrowUpSLine/>
													</button>
													<p className={style.paragraph}>{ t('other.click_for_details') }</p>
												</div>
												<div className={`${style['table__body']}`}>
													<div className={`${style['table__list']}`}>
														<div className={`${style['table__item']} ${style['table__item--1']}`}>
															{ t('report.organization_name') }
														</div>
														<div className={`${style['table__item']} ${style['table__item--1']}`}>
															{reportX.Kkm.Company.FullName}
														</div>
														<div className={`${style['table__item']} ${style['table__item--1']}`}>
															{ t('report.tax_id') }
														</div>
														<div className={`${style['table__item']} ${style['table__item--1']}`}>
															{reportX.Kkm.Company.Bin}
														</div>
														<div className={`${style['table__item']} ${style['table__item--1']}`}>
															{ t('report.kkm') }
														</div>
														<div className={`${style['table__item']} ${style['table__item--1']}`}>
															{reportX.Kkm.Id}
														</div>
														<div className={`${style['table__item']} ${style['table__item--1']}`}>
															{ t('report.kgd_registration_number').split('|')[0] }
															<br/>
															{ t('report.kgd_registration_number').split('|')[1] }
														</div>
														<div className={`${style['table__item']} ${style['table__item--1']}`}>
															{reportX.Kkm.Rnm}
														</div>
														<div className={`${style['table__item']} ${style['table__item--1']}`}>
															{ t('report.cash_register_name') }
														</div>
														<div className={`${style['table__item']} ${style['table__item--1']}`}>
															{reportX.Kkm.Name}
														</div>
														<div className={`${style['table__item']} ${style['table__item--1']}`}>
															{ t('report.trading_point_address') }
														</div>
														<div className={`${style['table__item']} ${style['table__item--1']}`}>
															{reportX.Kkm.Address.Town.Name}, {reportX.Kkm.Address.Street} {reportX.Kkm.Address.House} {reportX.Kkm.Address.Flat}
														</div>
														<div className={`${style['table__item']} ${style['table__item--1']}`}>
															{ t('report.cashier') }
														</div>
														<div className={`${style['table__item']} ${style['table__item--1']}`}>
															{user.Name}
														</div>
													</div>
													<div className={`${style['table__list']}`}>
														<div className={`${style['table__item']} ${style['table__item--1']}`}>
															{ t('report.issued_receipts_alt') }
														</div>
														<div className={`${style['table__item']} ${style['table__item--1']}`}>
															{reportX.Count}
														</div>

														<div className={`${style['table__item']} ${style['table__item--1']}`}>
															{ t('report.balance_at_shift_opening').split('|')[0] }<br></br>{ t('report.balance_at_shift_opening').split('|')[1] }
														</div>
														<div className={`${style['table__item']} ${style['table__item--1']}`}>
															{formatAmount(reportX.BalanceOpen)} ₸
														</div>
													</div>

													<div className={`${style['table__list']} ${style['sub-items']}`}>
														<div className={`${style['table__item']} ${style['table__item--1']}`}>
															{ t('other.balance_at_x_report_time').split('|')[0] }<br></br>{ t('other.balance_at_x_report_time').split('|')[1] }
														</div>
														<div className={`${style['sub-items']}`}>
															<div className={`${style['sub-item']}`}>
																<div className={`${style['table__item']} ${style['table__item--1']}`}>
																	{ t('main.cash') }
																</div>
																<div className={`${style['table__item']} ${style['table__item--1']}`}>
																	{formatAmount(reportX.BalanceClose)} ₸
																</div>
															</div>
															<div className={`${style['sub-item']}`}>
																<div className={`${style['table__item']} ${style['table__item--1']}`}>
																	{ t('main.cashless') }
																</div>
																<div className={`${style['table__item']} ${style['table__item--1']}`}>
																	{formatAmount(reportX.BalanceCloseNonCash)} ₸
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className={`${style.card} ${style['card--white-nopadding']} ${style['mr-20']} ${style['ml-20']}`}>
										<div className={style.table}>
											<div className={style['table__content']}>
												<div
													className={style['table__header']}
												>
													<div>{ t('report.registered_receipts') }</div>
												</div>
												<div className={style['table__body']}>
													<div className={`${style['table__list']}`}>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>{ t('report.service_income') }</div>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>{ t('report.number_of_receipts') }</div>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>{reportX.Total.Incomes.Qty}</div>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>{ t('report.total_amount') }</div>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>
															{formatAmount(reportX?.Total?.Incomes?.Amount)} ₸
														</div>
													</div>
													<div className={`${style['table__list']}`}>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>{ t('report.service_expense') }</div>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>{ t('report.number_of_receipts') }</div>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>{reportX.Total.Expenses.Qty}</div>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>{ t('report.total_amount') }</div>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>
															{formatAmount(reportX?.Total?.Expenses?.Amount)} ₸
														</div>
													</div>
													<div className={`${style['table__list']}`}>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>{ t('report.sales') }</div>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>{ t('report.number_of_receipts') }</div>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>{reportX.Total.Sales.Qty}</div>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>{ t('report.total_amount') }</div>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>
															{formatAmount(reportX?.Total?.Sales?.Amount)} ₸
														</div>
													</div>
													<div className={`${style['table__list']}`}>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>{ t('report.sales_returns') }</div>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>{ t('report.number_of_receipts') }</div>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>{reportX.Total.Refunds.Qty}</div>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>{ t('report.total_amount') }</div>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>
															{formatAmount(reportX?.Total?.Refunds?.Amount)} ₸
														</div>
													</div>
													<div className={`${style['table__list']}`}>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>{ t('report.purchases') }</div>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>{ t('report.number_of_receipts') }</div>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>{reportX.Total.Purchases.Qty}</div>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>{ t('report.total_amount') }</div>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>
															{formatAmount(reportX?.Total?.Purchases?.Amount)} ₸
														</div>
													</div>
													<div className={`${style['table__list']}`}>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>{ t('report.purchase_returns') }</div>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>{ t('report.number_of_receipts') }</div>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>{reportX.Total.PurchaseRefunds.Qty}</div>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>{ t('report.total_amount') }</div>
														<div className={`${style['table__item']} ${style['table__item--2']}`}>
															{formatAmount(reportX?.Total?.PurchaseRefunds?.Amount)} ₸
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className={`${style['printBtn-wrapper']} hide-for-pdf`}>
										{Capacitor.isNativePlatform() ? <NewDownloadPDFButton absolutePosition={true} fileName='X-отчет'/> : <NewPrintButton absolutePosition={true}/>}
									</div>
								</div>
							</div>
							:
							<div className={`${style.card} ${style['card--white-nopadding']} ${style['mt-20']}`} style={{'width': '100%'}}>
								<div className={`${style['text-center']} ${style['pa-40']}`}>
									<p className={`${style.paragraph} ${style['paragraph--sub']}`}>
										{ t('other.no_open_shift_for_x_report').split('|')[0] }
										<br/>
										{ t('other.no_open_shift_for_x_report').split('|')[1] }
									</p>
								</div>
							</div>
						}
					</React.Fragment>
					:
				<Loader />
			}
		</React.Fragment>
	)
}

export default NewReportX
