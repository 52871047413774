import React from "react";
import style from '../../styles/pages/report/duplicates/NewReportZDuplicate.module.scss'
import NewReportZDuplicateTop from "./NewReportZDuplicateTop";
import NewReportZDuplicateSections from "./NewReportZDuplicateSections";
import NewReportZDuplicateInfo from "./NewReportZDuplicateInfo";
import NewReportZDuplicateOperations from "./NewReportZDuplicateOperations";
import { useTranslation } from "react-i18next";

const NewReportZDuplicate = ({ reportZChoosen, user, setReportZDuplicateIsOpen}) => {
	const { t } = useTranslation();

	return (
		<React.Fragment>
			<NewReportZDuplicateTop reportZChoosen={reportZChoosen} setReportZDuplicateIsOpen={setReportZDuplicateIsOpen} />
			<div className={`${style['flex-4']} ${style['mt-20']}`} id="report-container">
				<div className={`${style['print-title']}`} >{ t('report.duplicate_z_report') }</div>
				<div className={`${style['top-table']}`} >
					<NewReportZDuplicateInfo reportZChoosen={reportZChoosen} user={user} />
					<NewReportZDuplicateSections reportZChoosen={reportZChoosen} />
				</div>
				<NewReportZDuplicateOperations reportZChoosen={reportZChoosen} />
			</div>
		</React.Fragment>
	)
}

export default NewReportZDuplicate
