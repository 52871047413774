import React, {useEffect, useState} from "react"
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

import MobileNav from '../../../MobileNav/MobileNav';
import Title from "../modules/Title"
import RefundContainer from "./RefundContainer";
import style from "../../styles/pages/operation.module.scss"
import HeaderVersion from "../../HeaderVersion/HeaderVersion";
import { BASE_URL, getHeaders } from "../../../../Store/snippets";
import GoToPurchaseRefundModal from "../RefundModals/GoToAnotherRefundPageModal";
import CanNotBeRefundedModal from "../RefundModals/CanNotBeRefundedModal";
import { OPERATION_TYPES } from "../../../../constants/app";
import { Capacitor } from "@capacitor/core";

const RefundOperation = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const headers = getHeaders(props.history)
  const historyState = props.history.location.state;
  const saleInfoFromPurchaseRefund = historyState ? historyState.salePositionsFromPurchaseRefund : [];
  const snInfoFromPurchaseRefund = historyState ? historyState.snFromPurchaseRefund : '';
  const [searchedOperationPositions, setSearchedOperationPositions] = useState([])
  const [snForRefundsPosition, setSnForRefundsPosition] = useState('');
  const [isOpenGoToPurchaseModal, setOpenGoToPurchaseModal] = useState(false) // отображается когда тип операции = покупка
  const [isOpenErrorRefundModal, setOpenErrorRefundModal] = useState(false) // отображается когда тип операции не соответствует продаже или покупке

  useEffect(() => {
    if (!localStorage.getItem('PositionDetailsFromReport')) {
      return
    }

    props.context.getRefundPositionDetails()

    const operationIdFromLs = localStorage.getItem('OperationIdFromReport');
    if (operationIdFromLs) {
      props.context.setSnForRefunds(operationIdFromLs)
    }

    return () => {
      localStorage.removeItem('PositionDetailsFromReport')
      localStorage.removeItem('OperationIdFromReport')
      localStorage.removeItem('PositionDetailsIsCashTransaction')
    }
  }, [])

  useEffect(() => {
    if (saleInfoFromPurchaseRefund.length) {
      props.context.getRefundPositionDetails(saleInfoFromPurchaseRefund)
      props.context.setSnForRefunds(snInfoFromPurchaseRefund)
    }
  }, [saleInfoFromPurchaseRefund, snInfoFromPurchaseRefund])
  
	const fetchOperationInfo = (receiptId) => {
    props.context.setSnForRefunds(receiptId)
    
		axios.get(`${BASE_URL}/operations/${receiptId}`, { headers })
    .then(response => {
      const operationData = response.data.Data.Document;
      const isCashTransaction = operationData.Cash > 0;
      
      // если тип операции = продажа, то даем cделать возврат
      if (operationData.IdTypeDocument === OPERATION_TYPES.SALE.ID) {
        setSearchedOperationPositions(operationData.Positions);
        props.context.getRefundPositionDetails(operationData.Positions, isCashTransaction);    
      } else if (operationData.IdTypeDocument === OPERATION_TYPES.PURCHASE.ID) {
        setSearchedOperationPositions(operationData.Positions);
        setSnForRefundsPosition(receiptId);
        setOpenGoToPurchaseModal(true);
      } else {
        setOpenErrorRefundModal(true);
      }
    })
    .catch(e => {
      console.log(e);
      setOpenErrorRefundModal(true);
    })
	}

  const handleGoToPurchaseRefundClick = () => {
    history.push('purchase_refund_new', { 
      purchasePositionsFromSaleRefund: searchedOperationPositions,
      snFromSaleRefund: snForRefundsPosition
    });
  };

  return (
    <React.Fragment>
      {
        window.matchMedia("(min-width: 1100px)").matches ? (
          <HeaderVersion isActive={true} balances={props.context.Balances} {...props} />
        ) : (
          <MobileNav isActive={true} />
        )
      }
      {isOpenGoToPurchaseModal && 
        <GoToPurchaseRefundModal 
          closeModal={() => setOpenGoToPurchaseModal(false)} 
          handleGoToPurchaseRefundClick={handleGoToPurchaseRefundClick}
          currentOperationType={OPERATION_TYPES.REFUND.KEY}
        /> 
      }
      {isOpenErrorRefundModal &&
        <CanNotBeRefundedModal closeModal={() => setOpenErrorRefundModal(false)} />
      }
      <div className={`${style.main} ${style[`main--${Capacitor.getPlatform()}`]}`}>
        <div className={style.container}>
          <Title
            title={ t('receipt.refund_of_sale') }
            history={props.history} 
          />
          <RefundContainer context={props.context} history={props.history} fetchOperationInfo={fetchOperationInfo} />
        </div>
      </div>
    </React.Fragment>
  )
}

export default RefundOperation;
