/* eslint-disable eqeqeq */
import React from 'react';
import LogoFiscal from '../Svg/logo.svg?react';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-text-mask'
import HiddenPass from '../Svg/hidden-pass.svg?react';
import ShowPass from '../Svg/show-pass.svg?react';
import axios from 'axios';
import { FISCAL_DEV_HOST, FISCAL_API_DEV_URL, FISCAL_API_PROD_URL, PULT_API_DEV_URL, PULT_API_PROD_URL } from '../../constants/app';

const hostName = window.location.host;
let URL_URL = FISCAL_API_DEV_URL;
let BASE_URL = PULT_API_DEV_URL;

if (import.meta.env.MODE === 'production') {
    URL_URL = FISCAL_API_PROD_URL;
    BASE_URL = PULT_API_PROD_URL;
}

if (hostName === FISCAL_DEV_HOST) {
    BASE_URL = PULT_API_DEV_URL;
}

// const user = JSON.parse(sessionStorage.getItem("USER_DATA"))
export default class SmsConfirmation extends React.Component {    
    
    constructor() {
        super();
        this.state = { time: {}, seconds: 60, SMS: "", toggle: 1, pass: "", hide: true, errorMsg: '' };
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }

    secondsToTime(secs){
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    componentDidMount() {
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });
        this.startTimer();
    }

    resend = async () => {
        this.startTimer();
        try {
            const form_id = localStorage.getItem('form_id')
            await axios.post(`${BASE_URL}/auth/register/resend`, {
                "form_id": form_id
            })
            // console.log("RES", res)
        } catch (error) {
            console.log("ERROR", error)
        }
    }

    startTimer = async () => {
        if (this.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }else if(this.state.seconds === 0) {
            this.setState({
                seconds: 61
            })
            this.timer = setInterval(this.countDown, 1000)
        }
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });
        
        // Check if we're at zero.
        if (seconds === 0) { 
            clearInterval(this.timer);
        }
    }

    handleChange = (e) => {
        this.setState({
            SMS: e.target.value
        })
    }

    handleClick = async (e) => {
        e.preventDefault();
        try {
            const form_id = localStorage.getItem('form_id')
            const response = await axios.post(`${BASE_URL}/auth/register/confirm`,
            {
                "form_id": form_id,
                "code": this.state.SMS
            })
            if(response.status === 200) {
                e.preventDefault();
                this.setState({
                    toggle: 2,
                })
                // const Token = localStorage.setItem("Token", response.data.auth.token)
            }
            // var userInfo = JSON.parse(sessionStorage.getItem("USER_DATA"))
            // axios.post(`https://api.amplitude.com/2/httpapi`,
            // {
            //     api_key: "d314c6311f60a96a3d7ec5a856b85143",
            //     events: [
            //         {
            //             event_type: "account verified",
            //             event_category: "signup",
            //             event_name: "account verified",
            //             device_id: "10001",
            //             user_id: userInfo.Phone,
            //         }
            //     ]
            // })
        } catch (error) {
            if (error.response.status === 422) {
                this.setState({
                    errorMsg: error.response.data.message
                })
            }
            console.log("Error", error.response)
        }
    }

    handleChangePassword = (e) => {
        this.setState({
            pass: e.target.value
        })
    }

    handleIconClick = () => {
        this.setState({
            hide: !this.state.hide
        })
    }

    handleRegisterClick = async (e) => {

        // const log = user.Phone.replace(/\D+/g, '')

        if(this.state.pass.length > 0) {
            try {
                const Token = localStorage.getItem("Token");
                const response = await axios({
                    method: 'post',
                    url: `${BASE_URL}/auth/change-pass`,
                    headers: {
                        'Authorization': "Bearer " + Token,
                        'Content-type': 'application/json',
                    },
                    data: {
                        "password": this.state.pass
                    }
                })
                localStorage.setItem("Token", response.data.auth.token)
                this.props.history.push('/www/congratulations');

                // var userInfo = JSON.parse(sessionStorage.getItem("USER_DATA"))
                // axios.post(`https://api.amplitude.com/2/httpapi`,
                // {
                //     api_key: "d314c6311f60a96a3d7ec5a856b85143",
                //     events: [
                //         {
                //             event_type: "create password",
                //             event_category: "signup",
                //             event_name: "create password",
                //             device_id: "10001",
                //             user_id: userInfo.Phone,
                //         }
                //     ]
                // })

                // await axios.post(`https://api.amplitude.com/2/httpapi`,
                // {
                //     api_key: "d314c6311f60a96a3d7ec5a856b85143",
                //     events: [
                //         {
                //             event_type: "account created",
                //             event_category: "signup",
                //             event_name: "account created",
                //             device_id: "10001",
                //             user_id: userInfo.Phone,
                //         }
                //     ]
                // })
                
            } catch (error) {
                this.props.history.push('/www/congratulations');
            }
        }

        const Token = localStorage.getItem("Token");
        console.log(localStorage.getItem("Token"));
        let headers = {
            'Authorization': "Bearer " + Token,
            'Content-type': 'application/json',
        }
        try {
            const CompaniesResponse = await axios.get(`${URL_URL}/companies`, { headers });
            console.log(CompaniesResponse);
        } catch (error) {
            console.log(error)
        }

    // e.preventDefault();
    // try {
    //     const res = await axios.post(URL_URL + '/auth',
    //     {
    //         "Login": log,
    //         "Password": this.state.pass
    //     })
    //     const { Token, User } = res.data.Data
    //     localStorage.setItem('tokken', Token);
    //     localStorage.setItem('USER', JSON.stringify(User));
    //     localStorage.setItem('URL_URL', URL_URL);
    //     document.cookie = 'tokken=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    //     document.cookie = `tokken = ${Token};max-age=86340;path=/`;
    //     this.props.history.push(`/www/comps`)
    // } catch (error) {
    //     console.log(error)
    // }
}

    render(){
        const userData = JSON.parse(sessionStorage.getItem("USER_DATA"))
        return(
            <div className="auth text-center">
                <form className="form-signin">
                    <div className="reg-header">
                        <LogoFiscal/>
                        <Link to='/' className="reg-header-enterText">Вход</Link>
                    </div>
                    <div className="reg-form-title">
                        <h1 className='h3 mb-1 font-weight-normal'>Регистрация</h1>
                        {this.state.toggle === 1 ?
                        <p style={{
                            fontSize: "15px",
                            margin: "9px 0 30px"
                        }} className="text-muted">
                            Введите SMS с кодом подтверждения
                        </p>
                        :
                        null    
                    }
                        
                    </div>
                    <fieldset disabled={false} >
                    <label htmlFor="inputPhone" className="sr-only">
                        Номер телефона
                    </label>
                    <MaskedInput
                        disabled
                        showMask={false}
                        guide={true}
                        // placeholderChar={'*'}
                        placeholderChar={'\u2000'}
                        mask={['+', '7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                        inputMode="tel"
                        id="inputPhone"
                        name="login"
                        className="form-control"
                        placeholder={userData.Phone}
                        // value={login}
                        // onChange={handleLogin}
                        // autoFocus
                    />
                    {this.state.toggle === 1 ?
                        <div>
                            <label htmlFor="inputSMS" className="sr-only">
                                SMS
                            </label>
                            <input
                                type="number"
                                id="inputSMS"
                                name="sms"
                                className="form-control"
                                placeholder="Код из SMS"
                                required
                                style={{marginTop: "10px"}}
                                onChange={this.handleChange}
                                autoFocus
                                // value={password}
                                // onChange={handlePassword}
                                // ref={passField}
                            />
                            {this.state.errorMsg.length > 0 ?
                                <div className="danger">{this.state.errorMsg}</div>
                                : null
                            }
                        </div>
                        :
                        <div>
                            <label htmlFor="inputPassword" className="sr-only">
                                Password
                            </label>
                            <div style={{position: "relative"}}>
                                <input
                                    // type="password"
                                    type={this.state.hide === true ? "password" : "text"}
                                    id="inputPassword"
                                    name="password"
                                    className="form-control"
                                    placeholder="Придумайте пароль"
                                    required
                                    value={this.state.pass}
                                    style={{marginTop: "10px"}}
                                    onChange={this.handleChangePassword}
                                    autoFocus
                                    // value={password}
                                    // onChange={handlePassword}
                                    // ref={passField}
                                />
                                <button className="hiddenPass" onClick={this.handleIconClick} type="button">{this.state.hide === true ? <HiddenPass/> : <ShowPass/>}</button>
                            </div>
                        </div>
                    }
                    
                </fieldset>
                {this.state.toggle === 1 ?
                <div>
                    {this.state.SMS.length === 0 ? 
                        <div>
                            {
                                this.state.seconds !== 0 ?
                                <p style={{
                                    fontSize: "15px",
                                    margin: "30px 0 30px",
                                    height: "45px",
                                }} className="text-muted">
                                    Отправить SMS с кодом подтверждения повторно можно будет через <span>{this.state.time.m} : {this.state.time.s}</span>
                                </p>
                                :
                                    <p style={{
                                        margin: "30px 0 30px",
                                        height: "45px",
                                        width: "auto",
                                        fontWeight: "600",
                                        fontSize: "16px",
                                        }} className="text-muted" onClick={this.resend}>Переотправить
                                    </p>
                            }
                        </div>
                        :
                        <div style={{
                            height: "45px",
                            margin: "30px 0 30px",
                        }}>
                            <button className={`mt-4 btn btn-primary btn-block`} type="button" onClick={this.handleClick}>
                                Подтвердить
                            </button>
                        </div>
                        
                    }
                </div>
                :
                    <div>
                        <button className={`mt-4 btn btn-primary btn-block`} type="button" onClick={this.handleRegisterClick}>
                            Зарегистрироваться
                        </button>
                        <p style={{
                            fontSize: "15px",
                            margin: "30px 0 30px"
                            }} className="text-muted">
                            Регистрируясь на сервисе Fiscal24, вы автоматически соглашаетесь с условиями <a alt="offer" href="https://pult24.kz/docs/pult24-offer.pdf">Оферты на оказание услуг</a>
                        </p>
                    </div>
                }
                
                {this.state.toggle === 1 && 
                <div className="register-button">
                    <Link to='/register'>
                        <button
                            className="btn btn-secondary btn-block mt-4"
                            type="button"
                        >
                            Назад
                        </button>
                    </Link>
                </div>}
                <div className="bottomPhrases">
                    <p className={`mb-3 text-muted`}>
                        &copy; 2016-2020 Fiscal24
                    </p>
                    <p className="text-muted">
                        v 0.1.3
                    </p>
                </div>
                </form>
            </div>
        )
    }
}