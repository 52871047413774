import React from "react";
import style from './Footer.module.scss'
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation()
    const currentYear = new Date().getFullYear();

    return <footer className={`${style.footer} ${style.new}`}>
        <p>{ t('auth.online_cash_register_from_kassa24_business') }<span>© {currentYear}</span></p>
    </footer>
}

export default Footer;
